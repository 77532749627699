.container {
	margin: auto;
	max-width: 500px;
	text-align: center;
}
.footer {
    text-align: center;
    bottom: 0;
    width: 100%;
  }
@media (max-width: 768px) {
	.container {
		width: 100%;
	}
}